/** @jsx jsx */
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import {colors} from '../../../styles/theme';
import {H3Mono, P} from '../../common/Typography';
import NoiseBackgroundImage from '../../../images/product/analytics/noise.png';
import {DefaultForm, FormError} from '../../common/Forms';
import {SecondaryButton} from '../../common/Clickables';
import BaseCard from './BaseCard';
import {getBlogNewslistFormSchema, newslistHubspotFormId} from '../../../helpers/constants/forms';
import {formPostToHubspot, validateFormData} from '../../../helpers/utils/forms';


/*
 * Private Elements
 */
const StyledBlogNewsletterCard = styled.div`
    border-radius: 5px;
    background: repeat url(${NoiseBackgroundImage}),
                linear-gradient(45deg, ${colors.purple} 0%, ${colors.darkPurple} 60%, #000000 120%);

    span {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
    }
`;

const StyledBlogNewsletterForm = styled(DefaultForm)`
    ${SecondaryButton} {
        float: right;
        margin-top: 10px;
    }
`;

const BlogNewsletterForm = ({schema}) => {
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const formElements = schema.map(input => {
        const id = `blogNewsletterForm-${input.name}`;

        const onChange = event => {
            // Clean error message when the form values changes
            if (formErrors?.message) {
                formErrors.message = null;
            }

            setFormErrors({...formErrors, fields: {...formErrors?.fields, [input.name]: null}});
            setFormData({...formData, [input.name]: input.getValue ? input.getValue(event) : event.target.value});
        };

        const errorMessages = formErrors.fields?.[input.name];
        // eslint-disable-next-line i18next/no-literal-string
        const errorClass = errorMessages && errorMessages.length ? 'error' : '';

        return (
            <div className="form-group" key={input.name}>
                <label htmlFor={id}>
                    {input.label}
                </label>

                <input.element
                    className={`form-control ${errorClass}`}
                    id={id}
                    name={input.name}
                    value={formData[input.name] || ''}
                    onChange={onChange}
                    disabled={isSubmitting}
                    {...input.attributes}
                />

                <FormError messages={errorMessages} />
            </div>
        );
    });

    const submitForm = async event => {
        event.preventDefault();
        setIsSubmitting(true);
        setFormErrors({});

        try {
            const frontendErrors = validateFormData(formData, schema);
            if (frontendErrors) {
                setFormErrors(frontendErrors);
                return false;
            }

            const formPostResult = await formPostToHubspot(newslistHubspotFormId, formData);
            if (!formPostResult) {
                setFormErrors({
                    message: [<Trans>Couldn&apos;t submit response. Please, try again later.</Trans>],
                });
                return false;
            }
            setIsSubmitted(true);
        } finally {
            setIsSubmitting(false);
        }
        return true;
    };

    // If the form is submitted, simply show the thank you message...
    if (isSubmitted) {
        return (
            <span>
                <H3Mono><Trans>Thanks for joining!</Trans></H3Mono>
                <P><Trans>You will receive notifications about upcoming events in your email address.</Trans></P>
            </span>
        );
    }

    return (
        <>
            <H3Mono><Trans>Join our list and be the first to learn about upcoming events.</Trans></H3Mono>
            <StyledBlogNewsletterForm>
                {formElements}

                <SecondaryButton
                    type="submit"
                    onClick={submitForm}
                    disabled={isSubmitting}
                >
                    <Trans>Submit</Trans>
                </SecondaryButton>
            </StyledBlogNewsletterForm>
        </>
    );
};

BlogNewsletterForm.propTypes = {
    schema: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};


/*
 * Public Elements
 */
const BlogNewsletterCard = () => {
    const {t} = useTranslation();
    const blogNewslistFormSchema = getBlogNewslistFormSchema(t);

    return (
        <StyledBlogNewsletterCard>
            <BaseCard>
                <BlogNewsletterForm schema={blogNewslistFormSchema} />
            </BaseCard>
        </StyledBlogNewsletterCard>
    );
};


/*
 * Exports
 */
export default BlogNewsletterCard;
