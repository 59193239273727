/** @jsx jsx */
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {Trans} from 'gatsby-plugin-react-i18next';

import {BaseBlogPostCard} from './BaseCard';
import {breakpoints, colors, fonts, fontWeights} from '../../../styles/theme';
import {postTypeFilters} from '../../../helpers/constants/learn';
import {ArrowCircleButton, LinkAsPrimaryButton} from '../../common/Clickables';
import {Link} from '../../common/Typography';


/*
 * Private Elements
 */
const StyledBlogPostCardContent = styled.div`
    height: 100%;

    display: flex;
    flex-direction: column;

    font-family: ${fonts.sansSerif};
    color: ${colors.white};

    p {
        margin: 0;
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: auto;

        color: ${colors.darkPurple};
        font-family: ${fonts.monospace};
        font-weight: ${fontWeights.semiBold};
        font-size: 10px;
        line-height: 19px;

        .category {
            svg {
                margin-right: 5px;

                * {
                    stroke: ${colors.darkPurple};
                }
            }

            p {
                font-size: 12px;
                line-height: 22px;
            }
        }
    }

    .bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;

        .left {
            flex-basis: 60ch;
            flex-grow: 1;
            padding-right: 1rem;
          
            .date {
                font-size: 10px;
                line-height: 13px;
                margin-bottom: 30px;
            }

            .title {
                font-size: 20px;
                font-weight: ${fontWeights.semiBold};
                line-height: 27px;
                margin-bottom: 20px;
            }

            .text {
                font-size: 12px;
                line-height: 19px;
            }
        }

        .right {
            margin-top: 1rem;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .top {
            font-size: clamp(10px, 1.5vw, 12px);
            line-height: clamp(19px, 2vw, 22px);
        }

        .bottom {
            .left {
                .date {
                    font-size: clamp(10px, 2vw, 12px);
                    line-height: clamp(13px, 2vw, 15px);
                }

                .title {
                    font-size: clamp(20px, 2vw, 24px);
                    line-height: clamp(27px, 2vw, 32px);
                }

                .text {
                    font-size: clamp(12px, 1.5vw, 14px);
                    line-height: clamp(19px, 1.5vw, 22px);
                }
            }
        }
    }
`;

const StyledClickableOverlay = styled(Link)`
    position: absolute;
    left: 50%;
    bottom: 50%;

    .arrow-circle-button {
        position: relative;
        transform: translateX(-50%) translateY(50%);
    }
`;

const ClickableOverlay = ({url}) => (
    <StyledClickableOverlay to={url}>
        <ArrowCircleButton className="arrow-circle-button" />
    </StyledClickableOverlay>
);

ClickableOverlay.propTypes = {
    url: PropTypes.string.isRequired,
};


/*
 * Public Elements
 */
const BlogPostCardContent = ({post, showButton = false}) => (
    <StyledBlogPostCardContent>
        <div className="top">
            <div className="category">
                {postTypeFilters[post.type].icon}
                <p>{postTypeFilters[post.type].name}</p>
            </div>
            <div className="labels">{post.blog_tags[0]?.tag}</div>
        </div>
        <div className="bottom">
            <div className="left">
                <div className="date">{post.publish_date}</div>
                <p className="title">{post.title}</p>
                <p className="text">{post.sub_title}</p>
            </div>
            {showButton && (
                <div className="right">
                    <LinkAsPrimaryButton to={`/learn/${post.id}`}>
                        <Trans>View this article</Trans>
                    </LinkAsPrimaryButton>
                </div>
            )}
        </div>
    </StyledBlogPostCardContent>
);

BlogPostCardContent.propTypes = {
    post: PropTypes.object.isRequired,
    showButton: PropTypes.bool,
};

BlogPostCardContent.defaultProps = {
    showButton: false,
};

const BlogPostCard = ({post}) => {
    const [hovered, setHovered] = useState(false);
    const overlayButton = hovered ? <ClickableOverlay url={`/learn/${post.id}`} /> : null;
    const onMouseEnter = () => setHovered(true);
    const onMouseLeave = () => setHovered(false);

    // Use the post preview image on the card if exists, else use the post image (hero image)
    return (
        <BaseBlogPostCard
            image={post.preview_image ? post.preview_image.localFile : post.feature_image.localFile}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {overlayButton}
            <BlogPostCardContent post={post} />
        </BaseBlogPostCard>
    );
};

BlogPostCard.propTypes = {
    post: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default BlogPostCard;
export {
    BlogPostCardContent,
};
