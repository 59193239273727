import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import SearchBar from '../common/filters/SearchBar';
import {LinkDark, PMono} from '../common/Typography';
import ArrowDown from '../../images/clickables/arrow_down.svg';
import {ScreenSizeContext} from '../../helpers/context';
import {breakpoints, colors} from '../../styles/theme';
import {orderedFilters, postTypeFilters} from '../../helpers/constants/learn';
import sendGoogleAnalyticsEvent from '../../helpers/utils/google-analytics';


/*
 * Constants
 */
const xlBreakpoint = parseInt(breakpoints.xl.replace('px', ''), 10);
const dropdownAnimationTime = 500;  // milliseconds
const smBreakpoint = parseInt(breakpoints.sm.replace('px', ''), 10);


/*
 * Helpers
 */
/**
 * All the filters to display on the dropdown (by removing the currently selected filter from the filters list)
 *
 * @param {string} selectedFilter - The currently selected filter
 * @returns {(string)[]}
 */
function getHiddenFilters(selectedFilter) {
    return orderedFilters.filter(filter => filter !== selectedFilter);
}


/*
 * Private Elements
 */
const Spacer = styled.span`
  width: 10px;
  display: inline-block;
`;

// Desktop Filters
const StyledDesktopFilterItem = styled.div`
    margin-bottom: 5px;

    ${LinkDark} {
        ${PMono} {
            display: inline;
        }

        &.active,
        &:hover {
            color: ${colors.darkPurple};

            svg {
                * {
                    stroke: ${colors.darkPurple};
                }
            }
        }
    }
`;

const DesktopFilterItem = ({active, filterData, setFilter}) => (
    <StyledDesktopFilterItem>
        <LinkDark onClick={setFilter} className={active ? 'active' : ''}>
            {filterData.icon}
            <Spacer />
            <PMono>{filterData.name}</PMono>
        </LinkDark>
    </StyledDesktopFilterItem>
);

DesktopFilterItem.propTypes = {
    active: PropTypes.bool.isRequired,
    filterData: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

const StyledDesktopFilters = styled.div`
    width: 100%;
    justify-content: space-between;
`;

const FlexBreak = styled.div`
    flex-basis: 100%;
    height: 5px;
`;

const DesktopFilters = ({filter, setFilter}) => {
    const screenSize = useContext(ScreenSizeContext);

    const _setFilter = f => () => {
        setFilter(f);

        // Send custom event to Google Analytics
        if (f) {
            /* eslint-disable-next-line i18next/no-literal-string */
            sendGoogleAnalyticsEvent('posts', 'filter-postType', f);
        }
    };
    const filters = orderedFilters.map(f => (
        <DesktopFilterItem
            key={f}
            active={filter === f}
            filterData={postTypeFilters[f]}
            setFilter={_setFilter(f)}
        />
    ));

    // If the screen width is not extra large, divide the filters into 2 rows
    if (screenSize.width < xlBreakpoint && orderedFilters.length >= 6) {
        filters.splice(Math.ceil(orderedFilters.length), 0, <FlexBreak key="flex-break" />);
    }

    return (
        <StyledDesktopFilters className="row">
            {filters}
        </StyledDesktopFilters>
    );
};

DesktopFilters.propTypes = {
    filter: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
};

// Mobile Filters
const StyledMobileFilterItem = styled.div`
    width: 100%;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 28px;

    &:first-of-type {
        padding-top: 20px;
    }

    &:last-of-type {
        padding-bottom: 20px;
    }

    ${LinkDark} {
        color: ${colors.black};

        svg {
            * {
                stroke: ${colors.black};
            }
        }

        ${Spacer} {
            margin-right: 30px;
        }

        ${PMono} {
            display: inline;
        }

        &:hover {
            color: ${colors.darkPurple};

            svg {
                * {
                    stroke: ${colors.darkPurple};
                }
            }
        }
    }
`;

const MobileFilterItem = ({filterData, setFilter}) => (
    <StyledMobileFilterItem>
        <LinkDark onClick={setFilter}>
            <Spacer>{filterData.icon}</Spacer>
            <PMono>{filterData.name}</PMono>
        </LinkDark>
    </StyledMobileFilterItem>
);

MobileFilterItem.propTypes = {
    filterData: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};

const StyledMobileFilters = styled.div`
    position: relative;
    width: 100%;

    .inverted {
        transform: scaleY(-1);
    }
  
    .selected-element {
        width: 100%;
        display: flex;
        justify-content: space-between;

        // Do not change color on hover/active/etc
        color: ${colors.white} !important;

        ${PMono} {
            display: inline;
        }

        svg {
            margin-top: auto;
            margin-bottom: auto;

            * {
                // Do not change color on hover/active/etc
                stroke: ${colors.white} !important;
            }
        }
    }

    .dropdown {
        position: absolute;
        top: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 230px;
        max-height: 0;
        z-index: 1;
        background-color: ${colors.white};
        border-radius: 5px;
        overflow: hidden;
        transition: max-height ${dropdownAnimationTime / 1000}s ease-out;
        box-shadow: 1px 3px 25px rgb(0, 0, 0, 0.3);

        &.open {
            max-height: 145px;
        }
    }
`;

const MobileFilters = ({filter, setFilter}) => {
    const [opened, setOpened] = useState(false);

    const toggleDropdown = () => setOpened(!opened);
    const _setFilter = f => () => {
        toggleDropdown();

        // Wait until the animation ends to switch the filters
        setTimeout(() => {
            setFilter(f);

            // Send custom event to Google Analytics
            if (f) {
                /* eslint-disable-next-line i18next/no-literal-string */
                sendGoogleAnalyticsEvent('posts', 'filter-postType', f);
            }
        }, dropdownAnimationTime);
    };

    // Define the selected and hidden filters
    const selectedFilter = postTypeFilters[filter];
    const hiddenFilters = getHiddenFilters(filter).map(f => (
        <MobileFilterItem
            key={f}
            filterData={postTypeFilters[f]}
            setFilter={_setFilter(f)}
        />
    ));

    /* eslint-disable-next-line i18next/no-literal-string */
    const arrowClasses = opened ? 'inverted' : '';
    /* eslint-disable-next-line i18next/no-literal-string */
    const dropdownClasses = opened ? 'open' : '';
    return (
        <StyledMobileFilters className="row channels-filters-mobile">
            <LinkDark onClick={toggleDropdown} className="selected-element">
                <span>
                    <span>{selectedFilter.icon}</span>
                    <Spacer />
                    <PMono>{selectedFilter.name}</PMono>
                </span>
                <ArrowDown className={arrowClasses} />
            </LinkDark>

            <div className={`dropdown ${dropdownClasses}`}>
                {hiddenFilters}
            </div>
        </StyledMobileFilters>
    );
};

MobileFilters.propTypes = {
    filter: PropTypes.string.isRequired,
    setFilter: PropTypes.func.isRequired,
};


/*
 * Public Elements
 */
const BlogFilters = ({filters, setFilters, filtersSchema}) => {
    const screenSize = useContext(ScreenSizeContext);

    // Do not spend time and resources rendering an inexistent element when the page first loads
    if (screenSize.width === 0) {
        return <></>;
    }

    // The search bar is the same on desktop and mobile
    const searchBar = (
        <SearchBar
            filters={filters}
            setFilters={setFilters}
            filtersSchema={filtersSchema}
            ignore={['type']}
            allowText
            /* eslint-disable i18next/no-literal-string */
            gaCategory="posts"
        />
    );

    // If mobile, render for it
    if (screenSize.width < smBreakpoint) {
        return (
            <>
                <MobileFilters
                    filter={filters.type || ''}
                    setFilter={type => setFilters({...filters, type})}
                />
                {searchBar}
            </>
        );
    }

    // Otherwise, render for desktop
    return (
        <>
            <DesktopFilters
                filter={filters.type || ''}
                setFilter={type => setFilters({...filters, type})}
            />
            {searchBar}
        </>
    );
};

BlogFilters.propTypes = {
    filters: PropTypes.object.isRequired,
    setFilters: PropTypes.func.isRequired,
    filtersSchema: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default BlogFilters;
