/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';

import {Trans} from 'gatsby-plugin-react-i18next';
import styled from '@emotion/styled';
import {jsx} from '@emotion/react';

import BlogNewsletterCard from './cards/BlogNewsletterCard';
import BlogPostCard from './cards/BlogPostCard';
import {PMono} from '../common/Typography';
import {newsletterPost} from '../../helpers/constants/learn';
import {breakpoints} from '../../styles/theme';


/*
 * Public Elements
 */
const StyledPostsList = styled.div`
    .blog-post {
        padding: 15px 0;

        &.empty {
            margin: auto;
            text-align: center;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .blog-post {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    @media (min-width: ${breakpoints.lg}) {
        padding-top: 105px;

        .blog-post {
            &.skewed {
                margin-top: -120px;
            }
        }
    }
`;

const BlogPostsList = ({posts}) => {
    const formattedPosts = posts.map((post, idx) => {
        // eslint-disable-next-line i18next/no-literal-string
        const skewedClass = (idx - 1) % 3 ? 'skewed' : '';
        const PostElement = post.type !== newsletterPost ? BlogPostCard : BlogNewsletterCard;
        return (
            <div className={`col-12 col-md-6 col-lg-4 blog-post ${skewedClass}`} key={post.id}>
                <PostElement post={post} />
            </div>
        );
    });

    // Add an empty message row if there are no posts
    if (!formattedPosts.length) {
        formattedPosts.push(
            <div className="col-12 blog-post empty" key="empty">
                <PMono><Trans>No blog posts match the selected filters.</Trans></PMono>
            </div>,
        );
    }

    return (
        <StyledPostsList className="row">
            {formattedPosts}
        </StyledPostsList>
    );
};

BlogPostsList.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};


/*
 * Exports
 */
export default BlogPostsList;
