/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';

import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {getImage} from 'gatsby-plugin-image';
import {BgImage} from 'gbimage-bridge';

import {hexToRgba} from '../../../helpers/utils';
import {breakpoints, colors, fonts} from '../../../styles/theme';


/*
 * Private Elements
 */
const StyledBaseCard = styled.div`
    padding: 1rem;
    height: clamp(500px, 50vw, 600px);
    width: 100%;
    font-family: ${fonts.sansSerif};
    color: ${colors.white};
    border-radius: 5px;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (min-width: ${breakpoints.md}) {
        padding: 2rem;
    }
`;

// Base Blog Post Card
const cardBackgroundImageFilteredCss = css`
    position: relative;
    width: 100%;

    &:before,
    &:after {
        border-radius: 5px;
        overflow: hidden;
        filter: grayscale(100%);
    }

    &:hover,
    &:focus-within {
        &:before,
        &:after {
            filter: none;
        }
    }

    &:focus-within {
        &:before,
        &:after {
            outline: 3px solid ${colors.darkPurple};
        }
    }
`;


/*
 * Public Elements
 */
const BaseCard = ({children}) => (
    <StyledBaseCard>
        {children}
    </StyledBaseCard>
);

BaseCard.propTypes = {
    children: PropTypes.node.isRequired,
};

const BaseBlogPostCard = ({image, onMouseEnter = () => {}, onMouseLeave = () => {}, children}) => {
    // Semi-transparent color gradient on top of the background image.
    // This helps the bottom text be (more) readable.
    const backgroundImageWithColorOverlay = [
        getImage(image),
        'linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 10%, rgba(255, 255, 255, 0) 40%)',
        `linear-gradient(to top, ${colors.darkPurple} 5%, ${hexToRgba(colors.darkPurple, 0)} 65%)`,
    ].reverse();

    return (
        <BgImage
            image={backgroundImageWithColorOverlay}
            css={cardBackgroundImageFilteredCss}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <BaseCard>
                {children}
            </BaseCard>
        </BgImage>
    );
};

BaseBlogPostCard.propTypes = {
    image: PropTypes.object.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    children: PropTypes.node.isRequired,
};

BaseBlogPostCard.defaultProps = {
    onMouseEnter: () => {},
    onMouseLeave: () => {},
};


/*
 * Exports
 */
export default BaseCard;
export {
    BaseBlogPostCard,
};
