import React from 'react';

import {Trans} from 'gatsby-plugin-react-i18next';

import Latest from '../../images/learn/filters/latest.svg';
import Article from '../../images/learn/filters/article.svg';
import Blog from '../../images/learn/filters/blog.svg';
import CaseStudy from '../../images/learn/filters/case-study.svg';
import Podcast from '../../images/learn/filters/podcast.svg';
import Report from '../../images/learn/filters/report.svg';
import Webinar from '../../images/learn/filters/webinar.svg';
import WhitePaper from '../../images/learn/filters/whitepaper.svg';


/*
 * Public Elements
 */
const newsletterPost = 'NEWSLETTER_POST';
const latestPostTypeFilter = '';
const articlePostTypeFilter = 'ARTICLE';
const blogPostTypeFilter = 'BLOG';
const caseStudyPostTypeFilter = 'CASE_STUDY';
const podcastPostTypeFilter = 'PODCAST';
const reportPostTypeFilter = 'REPORT';
const webinarPostTypeFilter = 'WEBINAR';
const whitepaperPostTypeFilter = 'WHITEPAPER';
const postTypeFilters = {
    [latestPostTypeFilter]: {name: <Trans>Latest</Trans>, icon: <Latest />},
    [articlePostTypeFilter]: {name: <Trans>Article</Trans>, icon: <Article />},
    [blogPostTypeFilter]: {name: <Trans>Blog</Trans>, icon: <Blog />},
    [caseStudyPostTypeFilter]: {name: <Trans>Case Study</Trans>, icon: <CaseStudy />},
    [podcastPostTypeFilter]: {name: <Trans>Podcast</Trans>, icon: <Podcast />},
    [reportPostTypeFilter]: {name: <Trans>Report</Trans>, icon: <Report />},
    [webinarPostTypeFilter]: {name: <Trans>Webinar</Trans>, icon: <Webinar />},
    [whitepaperPostTypeFilter]: {name: <Trans>Whitepaper</Trans>, icon: <WhitePaper />},
};
const orderedFilters = [
    latestPostTypeFilter,
    articlePostTypeFilter,
    blogPostTypeFilter,
    caseStudyPostTypeFilter,

    // TODO: enable these filters when there are posts of this type
    // podcastPostTypeFilter,
    // reportPostTypeFilter,
    // webinarPostTypeFilter,
    // whitepaperPostTypeFilter,
];

const blogFiltersSchema = {
    /* eslint-disable i18next/no-literal-string */
    type: {
        type: 'string',
        operator: 'equals',
        name: <Trans>Type</Trans>,
    },
    /* eslint-enable i18next/no-literal-string */
};


/*
 * Exports
 */
export {
    blogFiltersSchema,
    newsletterPost,
    latestPostTypeFilter,
    articlePostTypeFilter,
    blogPostTypeFilter,
    caseStudyPostTypeFilter,
    podcastPostTypeFilter,
    reportPostTypeFilter,
    webinarPostTypeFilter,
    whitepaperPostTypeFilter,
    postTypeFilters,
    orderedFilters,
};
