import React, {useContext, useRef, useState} from 'react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import Pagination from '../components/common/Pagination';
import AnimatedBackground from '../components/common/AnimatedBackground';
import SEO from '../components/common/SEO';
import {CTALink} from '../components/common/Typography';
import BlogFilters from '../components/learn/BlogFilters';
import BlogPostsList from '../components/learn/BlogPostsList';
import FeaturedPosts from '../components/learn/FeaturedPosts';
import {blogFiltersSchema, newsletterPost} from '../helpers/constants/learn';
import useFilters from '../helpers/hooks/useFilters';
import usePagination from '../helpers/hooks/usePagination';
import useFiltersFromQueryParameters from '../helpers/hooks/useFiltersFromQueryParameters';
import BlogShapesBackground from '../images/learn/shapes.png';
import {breakpoints, colors, ContentContainer} from '../styles/theme';
import {ContrastContext} from '../helpers/context';


/*
 * Constants
 */
const elementsPerPage = 9;


/*
 * Helpers
 */
/**
 * Add the custom newsletter post to the blog entries, so that it always appears before the last item in the first page
 * of results (if, and only if, no filters are applied).
 *
 * @param {Object[]} postsList - the list of posts to process
 * @param {Object} filters - the currently applied filters
 * @returns {Object[]}
 */
const addNewsletterPost = (postsList, filters) => {
    const numberOfPosts = postsList.length;

    if (!numberOfPosts || Object.values(filters).filter(val => !!val).length) return postsList;

    const idxToInsert = numberOfPosts < elementsPerPage ? numberOfPosts - 1 : elementsPerPage - 2;
    // eslint-disable-next-line i18next/no-literal-string
    postsList.splice(idxToInsert, 0, {type: newsletterPost, id: Math.random().toString(36).substring(2)});

    return postsList;
};


/*
 * Private Elements
 */
const HeroContainer = styled.div`
    position: relative;
`;

const HeroContentContainer = styled(ContentContainer)`
    min-height: 100vh;
    width: 100%;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${breakpoints.md}) {
        padding-left: 65px;
        padding-right: 65px;
    }
`;

const HeroContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .feature-articles {
        position: relative;

        .feature-articles-shapes {
            position: absolute;
            width: calc(100% + 65px);
            height: 100%;
            bottom: 40px;
            right: 0;
            background: url(${BlogShapesBackground}) no-repeat bottom;
            background-size: contain;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        flex-direction: row;
    }
`;

const HeroFiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LearnContentContainer = styled.div`
    background-color: ${colors.lightGrey};
    padding: 0 20px 60px;

    @media (min-width: 885px) {
        padding: 0 60px 70px;
    }
`;


/*
 * Public Elements
 */
const LearnPage = ({data, location}) => {
    const setContrast = useContext(ContrastContext);
    setContrast(false);
    const {t} = useTranslation();

    // Get the blog data
    const normalizedPosts = data.allStrapiBlogPost.edges.map(post => post.node);

    // Get the list of allowed filters for Posts
    const allowedPostsFilters = Object.keys(blogFiltersSchema);

    const [queryParamsFilters, setQueryParams] = useFiltersFromQueryParameters(location, allowedPostsFilters);

    // Set the selected filters and current page
    const [selectedFilters, setSelectedFilters] = useState(queryParamsFilters);
    const [currentPage, setCurrentPage] = useState(1);

    // Filter and paginate Posts
    const _filteredPosts = useFilters(normalizedPosts, selectedFilters, blogFiltersSchema);
    const filteredPosts = addNewsletterPost(_filteredPosts, selectedFilters);
    const paginatedPosts = usePagination(filteredPosts, currentPage, elementsPerPage);

    // Set the defined filters/page in the URL
    if (currentPage === 1) {
        setQueryParams({...selectedFilters});
    } else {
        setQueryParams({...selectedFilters, currentPage});
    }

    const setFilters = filters => {
        const f = {};
        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                f[key] = value;
            }
        });
        setSelectedFilters(f);

        // Changing filters MUST also change the pagination to the first page
        setCurrentPage(1);
    };

    const learnPaginationTopRef = useRef();

    return (
        <>
            <SEO
                title={t('Learn')}
                ogTitle={t('All About Payments - Switch Blog')}
                description={t('The Switch blog focuses on payment industry topics. Find the latest news, guidelines, '
                    + 'and state of the art content for payment optimization.')}
                image="/website/metatags/learn.png"
            />

            <HeroContainer>
                <AnimatedBackground>
                    <HeroContentContainer>
                        <HeroContent>
                            <div className="col-12 col-md-4 col-xl-3 feature-articles">
                                <div className="feature-articles-shapes" />
                                <CTALink><Trans>Feature Articles</Trans></CTALink>
                            </div>
                            <div className="col-12 col-md-8 col-xl-9">
                                <FeaturedPosts />
                            </div>
                        </HeroContent>

                        <HeroFiltersContainer>
                            <BlogFilters
                                filters={selectedFilters}
                                setFilters={setFilters}
                                filtersSchema={blogFiltersSchema}
                            />
                        </HeroFiltersContainer>
                    </HeroContentContainer>
                </AnimatedBackground>
            </HeroContainer>

            <LearnContentContainer ref={learnPaginationTopRef}>
                <ContentContainer>
                    <Pagination
                        numberOfElements={filteredPosts.length}
                        elementsPerPage={elementsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />

                    <BlogPostsList posts={paginatedPosts} />

                    <Pagination
                        numberOfElements={filteredPosts.length}
                        elementsPerPage={elementsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        scrollToRef={learnPaginationTopRef}
                    />
                </ContentContainer>
            </LearnContentContainer>
        </>
    );
};

LearnPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default LearnPage;
export const query = graphql`
    query($language: String!) {
        allStrapiBlogPost(
            filter: {language: {eq: $language}, status: {eq: "published"}}
            sort: {fields: publish_date, order: DESC},
        ) {
            edges {
                node {
                    id: strapiId
                    title
                    sub_title
                    publish_date(formatString: "DD MMMM YYYY")
                    type
                    blog_tags {
                        tag
                    }
                    ...BlogPostCardImagesFragment
                }
            }
        }
    }
`;
