import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import {BaseBlogPostCard} from './BaseCard';
import {BlogPostCardContent} from './BlogPostCard';


/*
 * Private Elements
 */
const StyledCardContent = styled.div`
    height: 100%;
    width: 100%;
`;


/*
 * Public Elements
 */
const BlogPostHeroCard = ({post}) => (
    // Use the post preview image on the card if exists, else use the post image (hero image)
    <BaseBlogPostCard image={post.preview_image ? post.preview_image.localFile : post.feature_image.localFile}>
        <StyledCardContent>
            <BlogPostCardContent post={post} showButton />
        </StyledCardContent>
    </BaseBlogPostCard>
);

BlogPostHeroCard.propTypes = {
    post: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default BlogPostHeroCard;
