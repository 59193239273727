import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {graphql, useStaticQuery} from 'gatsby';

import styled from '@emotion/styled';

import BlogPostHeroCard from './cards/BlogPostHeroCard';
import {SliderPagination} from '../common/Pagination';
import {breakpoints} from '../../styles/theme';


/*
 * Constants
 */
const sliderInterval = 5000;  // ms
const query = graphql`
    query {
        featuredPosts: strapiFeaturedPosts {
            learn {
                id
                title
                sub_title
                type
                publish_date(formatString: "DD MMMM YYYY")
                blog_tags {
                    tag
                }
                ...FeaturedBlogPostCardImagesFragment
            }
        }
    }
`;


/*
 * Private Elements
 */
const StyledFeaturedPosts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    @media (min-width: ${breakpoints.md}) {
        margin-top: 0;
        align-items: flex-end;
    }
`;


// Hides children component without unmounting it
const HideKeepMounted = ({isVisible, children}) => {
    const [hasBeenMounted, setHasBeenMounted] = useState(false);

    if (isVisible && !hasBeenMounted) {
        setHasBeenMounted(true);
    }

    return (
        <div style={{display: isVisible ? null : 'none', width: '100%', height: '100%'}}>
            {hasBeenMounted ? children : null}
        </div>
    );
};

HideKeepMounted.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};


/*
 * Public Elements
 */
const FeaturedPosts = () => {
    const [selectedPostIndex, setSelectedPostIndex] = useState(0);
    const {featuredPosts: {learn: blogFeaturedPosts}} = useStaticQuery(query);

    return (
        <StyledFeaturedPosts>
            {blogFeaturedPosts.map((post, index) => (
                <HideKeepMounted isVisible={index === selectedPostIndex} key={post.title}>
                    <BlogPostHeroCard post={blogFeaturedPosts[index]} />
                </HideKeepMounted>
            ))}
            <SliderPagination
                numberOfElements={blogFeaturedPosts.length}
                currentIndex={selectedPostIndex}
                setIndex={setSelectedPostIndex}
                rotationTimeout={sliderInterval}
            />
        </StyledFeaturedPosts>
    );
};


/*
 * Exports
 */
export default FeaturedPosts;
